// third-party
import { FormattedMessage } from 'react-intl';

// assets
import {
    IconBug,
    IconLayoutDashboard,
    IconUsers,
    IconUserCircle,
    IconChecklist,
    IconFileAnalytics,
    IconReceipt,
    IconShoppingCartDiscount,
    IconTicket,
    IconRocket,
    IconQuestionMark,
    IconSettings,
    IconBulb,
    IconChartBar,
    IconTruck,
    IconBrandMercedes,
    IconCalendarEvent,
    IconCalendarStats,
    IconConfetti,
    IconCash,
    IconSocial,
    IconMessage2,
    IconSubtask,
    IconUser,
    IconBuilding,
    IconSteeringWheel,
    IconClock,
    IconChevronRight,
    IconUserSearch,
    IconBuildingWarehouse,
    IconTruckLoading,
    IconBell,
    IconComponents,
    IconCalendarMinus,
    IconMapPin,
    IconWorld,
    IconBuildingBank,
    IconBuildingSkyscraper,
    IconBooks,
    IconShip,
    IconApiApp
} from '@tabler/icons';

// constant
const icons = {
    IconBug,
    IconLayoutDashboard,
    IconUsers,
    IconUserCircle,
    IconChecklist,
    IconFileAnalytics,
    IconReceipt,
    IconShoppingCartDiscount,
    IconTicket,
    IconRocket,
    IconChartBar,
    IconQuestionMark,
    IconSettings,
    IconBulb,
    IconTruck,
    IconBrandMercedes,
    IconCalendarEvent,
    IconCalendarStats,
    IconConfetti,
    IconCash,
    IconMessage2,
    IconSubtask,
    IconUser,
    IconBuilding,
    IconSteeringWheel,
    IconClock,
    IconChevronRight,
    IconSocial,
    IconUserSearch,
    IconBuildingWarehouse,
    IconTruckLoading,
    IconBell,
    IconComponents,
    IconCalendarMinus,
    IconMapPin,
    IconWorld,
    IconBuildingBank,
    IconBuildingSkyscraper,
    IconBooks,
    IconShip,
    IconApiApp
};

// ==============================|| EXTRA PAGES MENU ITEMS ||============================== //

const pages = {
    id: 'pages',
    type: 'group',
    permissionID: 'All',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            icon: icons.IconLayoutDashboard,
            url: '/dashboard',
            permissionID: 'All'
        },
        {
            id: 'social-lead',
            title: <FormattedMessage id="contacts" />,
            type: 'collapse',
            icon: icons.IconRocket,
            children: [
                {
                    id: 'social-lead',
                    title: <FormattedMessage id="contact list" />,
                    type: 'item',
                    icon: icons.IconApiApp,
                    url: '/contacts',
                    permissionID: 'business-read'
                },
                {
                    id: 'contactTracking',
                    title: <FormattedMessage id="contact tracking" />,
                    type: 'item',
                    icon: icons.IconApiApp,
                    url: '/contact-tracking',
                    permissionID: 'business-read'
                }
            ]
        },
        // {
        //     id: 'the-radar',
        //     title: <FormattedMessage id="Radar" />,
        //     type: 'item',
        //     icon: icons.IconRocket,
        //     url: '/the-radar',
        //     permissionID: 'radar-read'
        // },

        {
            id: 'customers',
            title: <FormattedMessage id="customers" />,
            type: 'item',
            icon: icons.IconRocket,
            url: '/customers',
            permissionID: 'Customer-read'
        },
        {
            id: 'cartons',
            title: <FormattedMessage id="cartons" />,
            type: 'item',
            icon: icons.IconRocket,
            url: '/cartons',
            permissionID: 'Carton-read'
        },
        {
            id: 'machines',
            title: <FormattedMessage id="machines" />,
            type: 'item',
            icon: icons.IconRocket,
            url: '/machines',
            permissionID: 'Machine-read'
        },
        {
            id: 'spare-parts',
            title: <FormattedMessage id="spare-parts" />,
            type: 'item',
            icon: icons.IconRocket,
            url: '/spareparts',
            permissionID: 'SparePart-read'
        },
        {
            id: 'quotation-request',
            title: <FormattedMessage id="quotes" />,
            type: 'item',
            icon: icons.IconRocket,
            url: '/quotes',
            permissionID: 'Quotes-read'
        },
        {
            id: 'Sample-request',
            title: <FormattedMessage id="Samples" />,
            type: 'item',
            icon: icons.IconRocket,
            url: '/samples',
            permissionID: 'Sample-read'
        },

        // {
        //     id: 'orders',
        //     title: <FormattedMessage id="orders" />,
        //     type: 'item',
        //     icon: icons.IconUsers,
        //     url: '/orders',
        //     permissionID: 'order-read'
        // },
        {
            id: 'staff',
            title: <FormattedMessage id="astronauts" />,
            type: 'item',
            icon: icons.IconRocket,
            url: '/astronauts',
            permissionID: 'Astronaut-read'
        },

        {
            id: 'reports',
            title: <FormattedMessage id="reports" />,
            type: 'item',
            icon: icons.IconChartBar,
            url: '/reports',
            permissionID: 'Report-read'
        },
        {
            id: 'careers',
            title: <FormattedMessage id="candidates" />,
            type: 'item',
            icon: icons.IconBell,
            url: '/careers',
            permissionID: 'Career-read'
        },

        {
            id: 'settings',
            title: <FormattedMessage id="settings" />,
            type: 'collapse',
            icon: icons.IconSettings,
            children: [
                // {
                //     id: 'roles-departments',
                //     title: <FormattedMessage id="roles-departments" />,
                //     type: 'collapse',
                //     icon: icons.IconSubtask,
                //     children: [
                //         // {
                //         //     id: 'roles',
                //         //     title: <FormattedMessage id="roles" />,
                //         //     type: 'item',
                //         //     icon: icons.IconUser,
                //         //     url: '/setting/roles',
                //         //     permissionID: 'Role-read'
                //         // },
                //         // {
                //         //     id: 'departments',
                //         //     title: <FormattedMessage id="departments" />,
                //         //     type: 'item',
                //         //     icon: icons.IconBuilding,
                //         //     url: '/setting/departments',
                //         //     permissionID: 'Department-read'
                //         // }
                //         // {
                //         //     id: 'documents-types',
                //         //     title: <FormattedMessage id="documents-types" />,
                //         //     type: 'item',
                //         //     icon: icons.IconBooks,
                //         //     url: '/setting/documents-types',
                //         //     permissionID: 'documentsTypes-read'
                //         // }
                //     ]
                // },
                // {
                //     id: 'staff-management',
                //     title: <FormattedMessage id="staff-management" />,
                //     type: 'collapse',
                //     icon: icons.IconUsers,
                //     children: [
                //         {
                //             id: 'shift',
                //             title: <FormattedMessage id="shift" />,
                //             type: 'item',
                //             icon: icons.IconClock,
                //             url: '/setting/shift',
                //             permissionID: 'Shift-read'
                //         },
                //         {
                //             id: 'leave-type',
                //             title: <FormattedMessage id="leave-type" />,
                //             type: 'item',
                //             icon: icons.IconCalendarMinus,
                //             url: '/setting/leave-type',
                //             permissionID: 'LeaveType-read'
                //         }
                //     ]
                // },
                {
                    id: 'front-setting',
                    title: <FormattedMessage id="front-setting" />,
                    type: 'item',
                    icon: icons.IconUsers,
                    url: '/setting/front-setting',
                    permissionID: 'frontsetting-read'
                },
                // {
                //     id: 'testimonial',
                //     title: <FormattedMessage id="testimonial" />,
                //     type: 'item',
                //     icon: icons.IconUsers,
                //     url: '/setting/testimonial',
                //     permissionID: 'testimonial-read'
                // },
                // {
                //     id: 'holiday',
                //     title: <FormattedMessage id="holiday" />,
                //     type: 'item',
                //     icon: icons.IconConfetti,
                //     url: '/setting/holidays',
                //     permissionID: 'Holiday-read'
                // },
                {
                    id: 'status',
                    title: <FormattedMessage id="Status" />,
                    type: 'item',
                    icon: icons.IconApiApp,
                    url: '/setting/status',
                    permissionID: 'Status-read'
                }
                // {
                //     id: 'country',
                //     title: <FormattedMessage id="country" />,
                //     type: 'item',
                //     icon: icons.IconApiApp,
                //     url: '/setting/countries',
                //     permissionID: 'Country-read'
                // },
                // {
                //     id: 'state',
                //     title: <FormattedMessage id="state" />,
                //     type: 'item',
                //     icon: icons.IconApiApp,
                //     url: '/setting/states',
                //     permissionID: 'State-read'
                // }
            ]
        },
        // {
        //     id: 'contact-managment',
        //     title: <FormattedMessage id="contacts" />,
        //     type: 'collapse',
        //     icon: icons.IconUserSearch,
        //     children: [
        //         {
        //             id: 'contactTracking',
        //             title: <FormattedMessage id="contact tracking" />,
        //             type: 'item',
        //             icon: icons.IconRocket,
        //             url: '/contact-tracking',
        //             permissionID: 'business-read'
        //         }
        //     ]
        // },
        {
            id: 'hrm',
            title: <FormattedMessage id="control humans" />,
            type: 'collapse',
            icon: icons.IconUserSearch,
            children: [
                {
                    id: 'attendance',
                    title: <FormattedMessage id="attendance" />,
                    type: 'item',
                    icon: icons.IconCalendarEvent,
                    url: '/hrm/attendance',
                    permissionID: 'Attendance-read'
                },
                {
                    id: 'leaves',
                    title: <FormattedMessage id="leaves" />,
                    type: 'item',
                    icon: icons.IconCalendarStats,
                    url: '/hrm/leaves',
                    permissionID: 'Leave-read'
                },
                {
                    id: 'announcement',
                    title: <FormattedMessage id="announcement" />,
                    type: 'item',
                    icon: icons.IconBell,
                    url: '/hrm/announcements',
                    permissionID: 'Announcement-read'
                },
                {
                    id: 'roles',
                    title: <FormattedMessage id="roles" />,
                    type: 'item',
                    icon: icons.IconUser,
                    url: '/setting/roles',
                    permissionID: 'Role-read'
                },
                {
                    id: 'departments',
                    title: <FormattedMessage id="spaceships" />,
                    type: 'item',
                    icon: icons.IconRocket,
                    url: '/setting/spaceships',
                    permissionID: 'Department-read'
                },
                {
                    id: 'shift',
                    title: <FormattedMessage id="shift" />,
                    type: 'item',
                    icon: icons.IconClock,
                    url: '/setting/shift',
                    permissionID: 'Shift-read'
                },
                {
                    id: 'leave-type',
                    title: <FormattedMessage id="leave-type" />,
                    type: 'item',
                    icon: icons.IconCalendarMinus,
                    url: '/setting/leave-type',
                    permissionID: 'LeaveType-read'
                },
                {
                    id: 'testimonial',
                    title: <FormattedMessage id="testimonial" />,
                    type: 'item',
                    icon: icons.IconUsers,
                    url: '/setting/testimonial',
                    permissionID: 'testimonial-read'
                },
                {
                    id: 'holiday',
                    title: <FormattedMessage id="holiday" />,
                    type: 'item',
                    icon: icons.IconConfetti,
                    url: '/setting/holidays',
                    permissionID: 'Holiday-read'
                }
            ]
        },
        // {
        //     id: 'support-tickets',
        //     title: <FormattedMessage id="support-tickets" />,
        //     type: 'item',
        //     icon: icons.IconTicket,
        //     url: '/support-tickets',
        //     permissionID: 'SupportTicket-read'
        // },

        // --------------
        {
            id: 'contact-managment',
            title: <FormattedMessage id="control contacts" />,
            type: 'collapse',
            icon: icons.IconUserSearch,
            children: [
                {
                    id: 'business',
                    title: <FormattedMessage id="business" />,
                    type: 'item',
                    icon: icons.IconApiApp,
                    url: '/contact-managment/business',
                    permissionID: 'business-read'
                },
                {
                    id: 'channel',
                    title: <FormattedMessage id="channel" />,
                    type: 'item',
                    icon: icons.IconApiApp,
                    url: '/contact-managment/channel',
                    permissionID: 'channel-read'
                },
                {
                    id: 'country',
                    title: <FormattedMessage id="country" />,
                    type: 'item',
                    icon: icons.IconApiApp,
                    url: '/setting/countries',
                    permissionID: 'Country-read'
                },
                {
                    id: 'state',
                    title: <FormattedMessage id="state" />,
                    type: 'item',
                    icon: icons.IconApiApp,
                    url: '/setting/states',
                    permissionID: 'State-read'
                },
                {
                    id: 'followUpAstronaut',
                    title: <FormattedMessage id="follow-up astronaut" />,
                    type: 'item',
                    icon: icons.IconApiApp,
                    url: '/contact-managment/follow-up-astronaut',
                    permissionID: 'followUpAstronaut-read'
                },
                {
                    id: 'followUpSupervisor',
                    title: <FormattedMessage id="follow-up supervisor" />,
                    type: 'item',
                    icon: icons.IconApiApp,
                    url: '/contact-managment/follow-up-supervisor',
                    permissionID: 'followUpSupervisor-read'
                },
                {
                    id: 'progressAstronaut',
                    title: <FormattedMessage id="progress astronaut" />,
                    type: 'item',
                    icon: icons.IconApiApp,
                    url: '/contact-managment/progress-astronaut',
                    permissionID: 'progressAstronaut-read'
                },
                {
                    id: 'statusSupervisor',
                    title: <FormattedMessage id="status supervisor" />,
                    type: 'item',
                    icon: icons.IconApiApp,
                    url: '/contact-managment/status-supervisor',
                    permissionID: 'statusSupervisor-read'
                }
            ]
        },
        {
            id: 'items-managment',
            title: <FormattedMessage id="control cartons" />,
            type: 'collapse',
            icon: icons.IconUserSearch,
            children: [
                {
                    id: 'style',
                    title: <FormattedMessage id="style" />,
                    type: 'item',
                    icon: icons.IconApiApp,
                    url: '/items-managment/style',
                    permissionID: 'style-read'
                },
                {
                    id: 'endurance',
                    title: <FormattedMessage id="Endurance" />,
                    type: 'item',
                    icon: icons.IconApiApp,
                    url: '/items-managment/endurance',
                    permissionID: 'endurance-read'
                },
                {
                    id: 'glued',
                    title: <FormattedMessage id="Glued" />,
                    type: 'item',
                    icon: icons.IconApiApp,
                    url: '/items-managment/glued',
                    permissionID: 'glued-read'
                },
                {
                    id: 'record',
                    title: <FormattedMessage id="record" />,
                    type: 'item',
                    icon: icons.IconApiApp,
                    url: '/items-managment/record',
                    permissionID: 'record-read'
                },
                {
                    id: 'strapling-direction',
                    title: <FormattedMessage id="straping direction" />,
                    type: 'item',
                    icon: icons.IconApiApp,
                    url: '/items-managment/strapling-direction',
                    permissionID: 'strapling-direction-read'
                },
                {
                    id: 'boxes-bound',
                    title: <FormattedMessage id="boxes/bound" />,
                    type: 'item',
                    icon: icons.IconApiApp,
                    url: '/items-managment/boxes-bound',
                    permissionID: 'boxes-bound-read'
                },
                {
                    id: 'item-types',
                    title: <FormattedMessage id="Items List" />,
                    type: 'item',
                    icon: icons.IconApiApp,
                    url: '/items-managment/item-types',
                    permissionID: 'item-types-read'
                },
                {
                    id: 'inks',
                    title: <FormattedMessage id="inks" />,
                    type: 'item',
                    icon: icons.IconApiApp,
                    url: '/items-managment/inks',
                    permissionID: 'inks-read'
                }
            ]
        },
        {
            id: 'candidates-managment',
            title: <FormattedMessage id="control candidates" />,
            type: 'collapse',
            icon: icons.IconUserSearch,
            children: [
                {
                    id: 'position',
                    title: <FormattedMessage id="position" />,
                    type: 'item',
                    icon: icons.IconUsers,
                    url: '/setting/position',
                    permissionID: 'position-read'
                }
            ]
        }
    ]
};

export default pages;
