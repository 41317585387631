import React, { useEffect, useReducer, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Avatar, Button, Grid, Stack, TextField, Typography } from '@mui/material';
import SubCard from 'ui-component/cards/SubCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { API_URL } from 'config';
import { put } from 'utils/axiosRequests';
import { useNavigate } from 'react-router-dom';
import { gridSpacing } from 'store/constant';
import useAuth from 'hooks/useAuth';
import accountReducer from 'store/accountReducer';
import axiosServices from 'utils/axios';
import { LOGIN } from 'store/actionType';
import { useDispatch } from 'react-redux';

// =============================  UPDATE PROFILE ======================================

const validationSchema = yup.object({
    // first_name: yup.string().required('First name is required.'),
    // last_name: yup.string().required('Last name is required.'),
    // phone_number: yup.string().required('Phone number is required.')
});

function UpdateProfile({ pageBehaviour }) {
    const { user, init } = useAuth();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [currentProfileImage, setCurrentProfileImage] = useState(`${API_URL}/${user?.user_avatar}`);

    const formik = useFormik({
        initialValues: {
            user_id: user?.user_id,
            first_name: user?.first_name || '',
            last_name: user?.last_name || '',
            email_address: user?.email_address || '',
            phone_number: user?.phone_number || '',
            user_avatar: ''
        },
        validationSchema,
        onSubmit: async (values) => {
            const formData = new FormData();
            formData.append('first_name', values.first_name);
            formData.append('last_name', values.last_name);
            formData.append('phone_number', values.phone_number);
            if (values.user_avatar) {
                formData.append('user_avatar', values.user_avatar);
            }
            try {
                await put(`api/users/update-profile`, formData);
                init();
            } catch (error) {
                console.error('Error updating user profile:', error);
            }
        }
    });

    const setCurrentImage = (e) => {
        e.preventDefault();
        setCurrentProfileImage(URL.createObjectURL(e.target.files[0]));
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <SubCard
                title="Profile Details"
                secondary={<Typography variant="h5">User ID: {user?.user_id}</Typography>}
                contentSX={{ textAlign: 'center' }}
            >
                <Grid container spacing={gridSpacing}>
                    <Grid item sm={6} md={4}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Avatar alt="User 1" src={currentProfileImage} sx={{ width: 90, height: 90, margin: '0 auto' }} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="subtitle2" align="center">
                                    Upload/Change Your Profile Image
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button variant="contained" component="label" style={{ color: '#ffffff !important' }}>
                                    Choose
                                    <input
                                        hidden
                                        accept="image/*"
                                        type="file"
                                        id="user_avatar"
                                        name="user_avatar"
                                        onChange={(event) => {
                                            formik.setFieldValue('user_avatar', event.currentTarget.files[0]);
                                            setCurrentImage(event);
                                        }}
                                    />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={6} md={8}>
                        <Grid container spacing={gridSpacing}>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="first_name"
                                    name="first_name"
                                    label="First Name*"
                                    InputLabelProps={{ shrink: true }}
                                    value={formik.values.first_name}
                                    onChange={formik.handleChange}
                                    error={formik.touched.first_name && Boolean(formik.errors.first_name)}
                                    helperText={formik.touched.first_name && formik.errors.first_name}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="last_name"
                                    name="last_name"
                                    label="Last Name*"
                                    InputLabelProps={{ shrink: true }}
                                    value={formik.values.last_name}
                                    onChange={formik.handleChange}
                                    error={formik.touched.last_name && Boolean(formik.errors.last_name)}
                                    helperText={formik.touched.last_name && formik.errors.last_name}
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    fullWidth
                                    id="email_address"
                                    name="email_address"
                                    label="Email*"
                                    InputLabelProps={{ shrink: true }}
                                    value={formik.values.email_address}
                                    disabled
                                    onChange={formik.handleChange}
                                    error={formik.touched.email_address && Boolean(formik.errors.email_address)}
                                    helperText={formik.touched.email_address && formik.errors.email_address}
                                />
                            </Grid>
                            <Grid item md={6} xs={6}>
                                <TextField
                                    fullWidth
                                    id="phone_number"
                                    name="phone_number"
                                    label="Phone*"
                                    InputLabelProps={{ shrink: true }}
                                    value={formik.values.phone_number}
                                    onChange={formik.handleChange}
                                    error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                                    helperText={formik.touched.phone_number && formik.errors.phone_number}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction="row" sx={{ justifyContent: 'flex-end' }}>
                                    <AnimateButton alignSelf="right">
                                        <Button variant="contained" type="submit" alignSelf="right">
                                            Update Details
                                        </Button>
                                    </AnimateButton>
                                </Stack>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </SubCard>
        </form>
    );
}

export default UpdateProfile;
